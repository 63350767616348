<template>
  <div :class="$style.container">
    <div :class="$style.wrapper">
      <Autocomplete
        :search="query.search"
        placeholder="Поиск"
        :valueNames="['name', 'phone', 'email', 'unp']"
        @querySearch="querySearch"
        @selectItem="handleSelectClientRequisites"
        @handleFindItems="getClientRequisites"
        @input="query.search = $event"
      />
      <el-button
        :class="$style.button"
        type="primary"
        @click="getClientRequisites"
      >
        Показать
      </el-button>
    </div>
    <el-table :data="requisites" stripe>
      <el-table-column label="Верифицирован" sortable>
        <template slot-scope="scope">
          <el-checkbox
            :checked="scope.row?.isVerified"
            :class="$style.checkbox"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Название" sortable />
      <el-table-column prop="unp" label="УНП" sortable />
      <el-table-column prop="phone" label="Телефон" sortable />
      <el-table-column label="ИП" sortable>
        <template slot-scope="scope">
          <el-checkbox
            :checked="scope.row?.isIndividualEntrepreneur"
            :class="$style.checkbox"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column prop="email" label="Почта" sortable />
      <el-table-column width="120">
        <template slot-scope="scope">
          <ActionButtons
            :add-trash="false"
            :edit-link="
              getRoute({
                route: $options.ADDSELLERS_ADMIN_ROUTES.CLIENT_REQUISITES.EDIT,
                params: { id: scope.row.id },
              })
            "
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="totalPages > 1"
      background
      layout="prev, pager, next"
      :page-size="query.limit"
      :total="count"
      :current-page.sync="query.page"
      :class="$style.pagination"
      @current-change="getClientRequisites"
    >
    </el-pagination>
  </div>
</template>

<script>
import delivery from '@/delivery'

import { ADDSELLERS_ADMIN_ROUTES, getRoute } from '@/constants/routing'

import Autocomplete from '@/components/atoms/Autocomplete.vue'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'

export default {
  components: { Autocomplete, ActionButtons },
  ADDSELLERS_ADMIN_ROUTES,
  data() {
    return {
      query: {
        limit: 20,
        page: 1,
        search: '',
      },
      requisites: [],
      count: 0,
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.count / this.query.limit)
    },
  },
  async created() {
    await this.getClientRequisites()
  },
  methods: {
    async querySearch({ queryString, setSearchItems }) {
      const query = {
        limit: 50,
        search: queryString,
      }
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddwineCore.ClientRequesitesActions.getList(query)

      loading.close()

      if (error) return

      setSearchItems(value.data)
    },
    async getClientRequisites() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddwineCore.ClientRequesitesActions.getList({
          ...this.query,
        })

      loading.close()

      if (error) return

      this.requisites = value.data
      this.count = value.meta.count
    },
    handleSelectClientRequisites(selectedProduct) {
      this.$router.push(
        this.getRoute({
          route: ADDSELLERS_ADMIN_ROUTES.CLIENT_REQUISITES.EDIT,
          params: { id: selectedProduct?.id },
        }),
      )
    },
    getRoute({ route, params }) {
      return getRoute({ route, params })
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 1rem;

  .wrapper {
    margin-bottom: 1rem;
  }

  .checkbox {
    pointer-events: none;
  }

  .pagination {
    @include pagination;
  }
}
</style>
